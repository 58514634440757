





























































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ListOfAssetsStateSelect from '../components/ListOfAssetsStateSelect.vue';
import { PaginatableMixin } from '@/views/mixin';
import { FETCH_LISTS_OF_ASSETS, SET_LIST_PAGE, SET_LIST_STATE } from '@/modules/listOfAssets/store';
import { ListOfAssets, ListsOfAssets, State } from '@/modules/listOfAssets/types';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppTable from '@/components/AppTable.vue';
import CardHeader from '@/components/CardHeader.vue';
import { routeToLocation } from '@/router';

const FinanceAccountExportDialog = () => import('@/modules/listOfAssets/components/FinanceAccountExportDialog.vue');
const ListSearchDialog = () => import('@/modules/listOfAssets/components/ListSearchDialog.vue');

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    CardHeader,
    AppTable,
    ErrorAlert,
    FinanceAccountExportDialog,
    ListOfAssetsStateSelect,
    ListSearchDialog
  }
})
export default class List extends PaginatableMixin {
  @ListOfAssets.State('listsOfAssets') list!: ListsOfAssets;
  @ListOfAssets.Mutation(SET_LIST_PAGE) setPage!: (page: number) => void;
  @ListOfAssets.Mutation(SET_LIST_STATE) setState!: (state: State | null) => void;
  @ListOfAssets.Action(FETCH_LISTS_OF_ASSETS) fetch!: () => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;

  pagination: Pagination = {
    sortBy: ['fullName'],
    itemsPerPage: 10,
    page: 1,
    descending: false
  };

  get stateFilter(): State | null {
    return this.$route.query['state'] as State || null;
  }

  set stateFilter(state: State | null) {
    this.setState(state);

    if (!state) {
      const { state, ...query } = this.$route.query;

      this.$router.push(routeToLocation({ ...this.$route, query })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, state } })).catch(() => {
    });
  }

  get items() {
    return this.list.items
      .filter((listOfAsset) => this.list.state === null || listOfAsset.state === this.list.state)
      .map(({ conservatee, ...listOfAsset }) => ({
        ...listOfAsset,
        fullName: `${conservatee.surname}, ${conservatee.forename}`
      }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'fullName' },
      ...(this.isSupport() ? [{ align: 'left', text: this.$i18n.t('common.conservator'), value: 'conservator' }] : []),
      { align: 'left', text: this.$i18n.t('common.attorneyDocket'), value: 'attorneyDocket' }
    ];
  }

  itemClasses(listOfAsset: ListOfAssets) {
    if (listOfAsset.state === 'closed') {
      return 'error';
    }

    return '';
  }

  async created() {
    if (!this.stateFilter) {
      this.stateFilter = this.list.state || null;
    }

    this.loading = true;

    try {
      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
